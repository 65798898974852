import React from 'react';
import {  Link } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import NavDropdown  from 'react-bootstrap/NavDropdown';
import Avatar from 'react-avatar';
import { useAuth0 } from "@auth0/auth0-react";
//import { LoginButton } from './loginbutton';
//import { LogoutButton } from './logoutbutton';
import { NavBarButtons } from './navbarauthbuttons';





function MainNavbar () {

  const { isAuthenticated, user } = useAuth0();

  const myStyle={
    navBar:{
      color: "white",
      opacity: "100%"

    },
    navBarMain: {
        opacity: "75%",
        backgroundColor: "#12477b",

        
    }
  }
    let brandTitle = "Accounting Analytics Cloud";
    let currentUser = "";

    //save for later
    /*
    {isAuthenticated && isAdmin === "admin" && 
                <Nav.Link style={myStyle.navBar} href="/adminmenu">Admin</Nav.Link>
              }
    */
    


  return (
    <Navbar variant="dark" style={myStyle.navBarMain} expand="lg">
    <Container className="w-95">
        <Navbar.Brand style={myStyle.navBar} href="/"><span style={{color: "white",fontWeight: "800"}}>{brandTitle}</span></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link style={myStyle.navBar} href="/">Home</Nav.Link>
              <Nav.Link style={myStyle.navBar} as={Link} to="/sampledata/" state={{initiallimit: "10"}}>Getting started</Nav.Link>
              <Nav.Link style={myStyle.navBar} href="/sampledata">Admin</Nav.Link>
            </Nav>
            <Nav>
              {isAuthenticated &&
                  
                  <NavDropdown title={<Avatar maxInitials={2} src={user.picture} round={true} color="gray" size="40px"name={user.name}/>} id="dropdown1" >
              
                      <NavBarButtons />
                          
                  </NavDropdown>
              
              }
              {!isAuthenticated &&
                  
                  
              
                      <NavBarButtons />
                          
                  
              
              }
                                
                            </Nav>
        </Navbar.Collapse>
    </Container>
</Navbar>
  );
}
export default MainNavbar;