import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainNavbar from './mainnavbar';
import background from "../assets/Background.png";
import Sampledata from './sampledata';
import Mainmenu from './mainmenu';
import { AuthenticationGuard } from './authentication-guard';
import {Profile} from '../components/profile';

function AppRoutes () {

  const myStyle={
    backgroundStyle:{
      backgroundImage: `url(${background})`,
      minHeight:'100vh',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      fontFamily: 'Montserrat',
    }
  }

  return(

<div style={myStyle.backgroundStyle}>
<Router>
  
  <MainNavbar/>
  <Routes>
    
      <Route path="/" element={<Mainmenu />}/>
      <Route path="/sampledata" element={<AuthenticationGuard component={Sampledata} />} />
      <Route path="/profile" element={<AuthenticationGuard component={Profile} />} />
    
  </Routes>
  
</Router>
</div>
  )
}

export default AppRoutes;