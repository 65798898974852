import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/profile",
      },
    });
  };

  return (
    <button type="link" className="btn btn-link" style={{textDecoration: "none", color: "white"}} onClick={handleLogin}>Log In</button>

  );
};