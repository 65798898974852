import axios from "axios";

/***********
The received responses from Axios, comprise the following information.

data - the payload returned from the server
status - the HTTP code returned from the server
statusText - the HTTP status message returned by the server
headers - headers sent by the server
config - the original request configuration
request - the request object

*/

export default axios.create({
    baseURL: "https://api.accountinganalyticscloud.com/",
})
