import React from "react";
import { Bars } from  'react-loader-spinner'

function PageLoader () {


    return(
  <div style={{position: "absolute" ,marginLeft: "50%", marginTop: "10%"}}>
        <Bars
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
    />

  </div>
    )
}

export default PageLoader;