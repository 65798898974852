import React , {useContext} from "react";
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import banner from "../assets/banner.png";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../style/home.css';


function Mainmenu (props) {
    
    //const usercontext = useContext(UserContext);

    // const userID = usercontext.userid;
    // const sessionID = usercontext.sessionid;
    // const sportID = usercontext.sportid;
    // const menuTitle = usercontext.menutitle;

    const myStyle={
        banner:{
          backgroundImage: `url(${banner})`,
          maxHeight:"364px",
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat'
         
        }
    };
    
    return (
        
            <Container className="w-75">
                <h1 className="subHeadingText"> Select a function </h1>
                <Row className="text-center">
                    <Col className="btmBtn" lg md sm>
                        <div className="d-flex align-items-center justify-content-center h-100 subDiv rounded">
                            <Link to="/sampledata/" style={{color:"white", textDecoration:"none"}} state={{linktype: "scores", scoresorsubs: "scores", showsubs: "false"}} >
                                <div className="btnText" >See Sample Data</div>
                            </Link>
                        </div>
                    </Col>
                    <Col className="btmBtn" lg md sm>
                        <div className="d-flex align-items-center justify-content-center h-100 subDiv rounded">
                            <Link to="/sampledata/" style={{color:"white", textDecoration:"none"}} state={{linktype: "scores", scoresorsubs: "scores", showsubs: "false"}} >
                                <div className="btnText" >See the Full Dataset</div>
                            </Link>
                        </div>
                    </Col>
                    <Col className="btmBtn" lg md sm>
                        <div className="d-flex align-items-center justify-content-center h-100 subDiv rounded">
                            <Link to="/sampledata/" style={{color:"white", textDecoration:"none"}} state={{linktype: "scores", scoresorsubs: "scores", showsubs: "false"}} >
                                <div className="btnText" >Create Test / Quiz Data</div>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container> 
        
    )
}
export default Mainmenu;