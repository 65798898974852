import {  useNavigate } from "react-router-dom";
import React from "react";
import left from "../assets/Left.png";
import '../style/home.css';


function GoBack () {
    
    const navigate = useNavigate();

    return (
        <>
            <span style={{ cursor: "pointer"}} onClick={() => navigate(-1)}><img src={left} className="goback" alt="left arrow" /></span>
        </>
    )

}
export default GoBack;