import { useAuth0 } from "@auth0/auth0-react";
import React, {useState, useEffect} from "react";
import { Button } from "react-bootstrap";
import api from "../api/connection.js";
import Avatar from "react-avatar";

export const Profile = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [allAppMetaData, setAllAppMetadata] = useState("");
  const [allUserRoles, setAllUserRoles] = useState([]);
  
  useEffect(() => {
    const getTheAppMetadata = async() => {
        
        const appMetadata = await getAppMetadata();
        if(appMetadata) {
            if (appMetadata === "Network error") {

                console.log("Network error");

            }
        else if(appMetadata.error) {
            
            return ("API Error:  " + appMetadata.message);
        }
        else {
          if (appMetadata.data.data.app_metadata !== undefined) {
            
            setAllAppMetadata(JSON.stringify(appMetadata.data.data.app_metadata));
          }
          else {
            setAllAppMetadata("");
          }
        }

      }          
    };

    getTheAppMetadata();


  }, [] );

  useEffect(() => {
    const getUserRoles = async() => {
        
        const userRoles = await getRoles();
        if(userRoles) {
            if (userRoles === "Network error") {

                console.log("Network error");

            }
        else if(userRoles.error) {
            
            return ("API Error:  " + userRoles.message);
        }
        else {
          if (userRoles.data !== undefined) {
            
              setAllUserRoles(userRoles.data);
 
          }
          else {
              setAllUserRoles([]);
          }
            
        
        }

        }          
    };

    getUserRoles();


  }, [] );

  
  if (!user) {
    return null;
  }
  
  
  const setAppMetaData = async (newKey, newSetting) => {
    
    
    try {
      var token = await getAccessTokenSilently();
        //console.log(token);
    
      try {
          let config = {
            
              headers: {
                Authorization: "Bearer " + token,
              }
            };
          let data = {
            newkey: {newKey},
            newmetadata: {newSetting}
        };
      
    
          var apiResponse = await api.post("/user/setappmetadata" ,data, config)
          
          
          setAllAppMetadata(newSetting);
          
      
        }
        catch (err) {
    
          if (err.response) {
            const returnError = "Connect error: " + err.response;
            console.log("ERROR: " + returnError);
            return "Network error";
          }
          else if (err.request) {
            console.log ("No response");
            return "No response";
          }
          else {
            console.log("other error");
            return "Undetermined error";
          }
        }
      }
    catch {
      console.log("error getting token");
    }
  }

  const getAppMetadata = async () => {
    
    try {
      var token = await getAccessTokenSilently();
        //console.log(token);
    
      try {
          let config = {
            
              headers: {
                Authorization: "Bearer " + token,
              }
            };
    
          var apiResponse = await api.get("/user/getappmetadata", config)
          
          
          //console.log(isAdmin);
          
      
        }
        catch (err) {
    
          if (err.response) {
            const returnError = "Connect error: " + err.response;
            console.log("ERROR: " + returnError);
            return "Network error";
          }
          else if (err.request) {
            console.log ("No response");
            return "No response";
          }
          else {
            console.log("other error");
            return "Undetermined error";
          }
        }
        return (apiResponse)
      }
    catch {
      console.log("error getting token");
    }
  }
  const getRoles = async () => {
    
    
    try {
      var token = await getAccessTokenSilently();
        //console.log(token);
    
      try {
          let config = {
            
              headers: {
                Authorization: "Bearer " + token,
              }
            };
    
          var apiResponse = await api.get("/user/getuserroles", config)
          
          
          //console.log(isAdmin);
          
      
        }
        catch (err) {
    
          if (err.response) {
            const returnError = "Connect error: " + err.response;
            console.log("ERROR: " + returnError);
            return "Network error";
          }
          else if (err.request) {
            console.log ("No response");
            return "No response";
          }
          else {
            console.log("other error");
            return "Undetermined error";
          }
        }
        return (apiResponse)
      }
    catch {
      console.log("error getting token");
    }
  }
  const RenderRoles = () => {
    if (allUserRoles.data !== undefined) {
      if (allUserRoles.data.length > 0) {
    
        return (
          allUserRoles.data.map((role) => {
            return (
              <div key={role.id}>
                <span>Role: {role.name} </span><br />
                <span>Role Description: {role.description} </span>
              </div>
            )
          })
        )
      }
    }
  }


  return (
    
      <div className="content-layout">
        
        <h1 id="page-title" className="content__title">
          Profile Page
        </h1>
        <div className="content__body">
        <Avatar maxInitials={2} round={true} color="gray" size="40px" name={user.name} src={user.picture} />
          <div id="page-description">
            <span>
              <strong>Only authenticated users can access this page.</strong>
            </span>
            <p>
                <Button onClick={() => setAppMetaData("new_user",true)}>Test auth0 Mgmt - Set True</Button>
            </p>
            <p>
                <Button onClick={() => setAppMetaData("new_user", false)}>Test auth0 Mgmt - Set False</Button>
            </p>
            <p style={{color: "white"}}>

                <span>App Metadata: {allAppMetaData}</span>
              
            </p>
            <span style={{color: "white"}}>

                <RenderRoles />
              
            </span>
            
          </div>

            <div style={{color: "white"}}>

              <div style={{color: "white"}}>
                <h2 style={{color: "white"}}>{user.name}</h2>
                <span style={{color: "white"}}>{user.email}</span>
              </div>
            </div>

        </div>
      </div>
    
  );
};