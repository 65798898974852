import React, { useEffect, useState  } from "react";
import api from "../api/connection.js";
import GoBack from "./goback.js";


    
    const SampleData = (props) => {
        
        const [acctData, setAcctdata] = useState([]);

        useEffect(() => {
            const getAllSampleData = async() => {
                
              await getSampleData(10);
                  
            }
            getAllSampleData();
    
            
        }, [] );

        const getSampleData = async (limit) => {
          var apiResponse = null;
          //const limit = 10;
          try {
            apiResponse = await api.get("/randomapsample/" + limit);

            if(apiResponse) {
              if (apiResponse === "Network error") {

                  console.log("Network error");

              }
              else if(apiResponse.data.error) {
              
                  console.log("API Error:  " + apiResponse.data.message);
              }
              else {
              
                  setAcctdata(apiResponse.data.data);      
          
              }
            }
            else {
              setAcctdata([]);
            }   
          }  
          catch (err) {
              if(err.response) {
                  console.log("An error was encountered when processing in the API:  " + err);
              }
              else if(err.data.code === "ER_PARSE_ERROR") {
                  console.log("A SQL error occurred:  " + err.data.sqlMessage);
              }
              else if (err.request) {
                  console.log("An error was encountered with the call to the API:  " + err.request);
              }
              else {
                  console.log("There was an unknown error:  " + err.message);
              }
          }
          
          return apiResponse;
      }        

    
        const RenderSampleData = () => {

          if (acctData.length > 0) {
         
             return (
                 acctData.map((sample) => {
         
                 
                     return (
                 
                         <tr key={sample.ID}>
                             <td className="standings-td-name">
                                 {sample.ID}
                             </td>
                             <td className="standings-td-number">
                                 {sample['Company Code']}
                             </td>
                             <td className="standings-td-name" >
                                 {sample['Vendor Name']}
                             </td>
                             <td className="standings-td-name" style={{textTransform: "capitalize"}}>
                             {sample['Company Code']}
                             </td>
                             <td className="standings-td-name" style={{textTransform: "capitalize"}}>
                             {sample['Inv Number']}
                             </td>
                             <td className="standings-td-name" >
                             {sample['Inv Date'].substring(0,10)}
                             </td>
                             <td className="standings-td-number" >
                                 {sample.Amount}
                             </td>
                         </tr>    
                     )
                 })
             )
         }
     }        
    
     return (
      <>
          <GoBack /><button className="btn btn-outline" role="link" style={{position: "absolute", color: "white", paddingRight: "100px"}}>Download Data</button><br/>
          <button className="btn btn-outline" role="link" onClick={() => getSampleData(10)} style={{color: "lightGreen"}}>Get New Sample (10)</button>
<button className="btn btn-outline" role="link" onClick={() => getSampleData(20)} style={{color: "lightGreen"}}>Get New Sample (20)</button>
<button className="btn btn-outline" role="link" onClick={() => getSampleData(1000)} style={{color: "lightGreen"}}>Get Large Sample (1,000)</button>
  <div style={{height: "200vh",overflowY: "auto" }}>
    <div className="table-responsive-md" style={{position: "absolute", paddingTop: "10vh", width: "95%"}}>
      <table className="table table-hover table-bordered" style={{ marginLeft: "2vh" ,borderBlockColor: "black", backgroundColor: "lightgray",opacity: "87%" }}>
        <thead>
          <tr>
            <th className="standings-td-name-header">ID</th>
            <th className="standings-td-name-header">Vendor No</th>
            <th className="standings-td-name-header">Vendor Name</th>
            <th className="standings-td-name-header">Company Code</th>
            <th className="standings-td-name-header">Inv Number</th>
            <th className="standings-td-name-header">Inv Date</th>
            <th className="standings-td-name-header">Amount</th>
          </tr>
        </thead>
        <tbody>
          <RenderSampleData />
        </tbody>
      </table>
      </div>
  </div>
      </>
  )
    }
    

export default SampleData;